import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import {
  BookOpen,
  Award,
  Target,
  Calendar,
  ChevronRight,
  AlertTriangle,
  Trash2,
  Plus,
  BarChart,
  Timer
} from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../ui/alert-dialog';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { motion, AnimatePresence } from 'framer-motion';

const AccountPage = () => {
  const { user, deleteExam } = useContext(UserContext);
  const navigate = useNavigate();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [examToDelete, setExamToDelete] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Calculate user stats
  const stats = {
    activeCerts: user?.savedExams?.length || 0,
    totalAttempts: user?.savedExams?.reduce((sum, exam) => {
      const domainAttempts = Object.values(exam.domains || {}).reduce(
        (domainSum, domain) => domainSum + (domain.attempts?.length || 0),
        0
      );
      const examAttempts = exam.exams?.length || 0;
      return sum + domainAttempts + examAttempts;
    }, 0) || 0,
    lastActive: user?.savedExams?.reduce((latest, exam) => {
      const examDate = new Date(exam.lastAttemptDate || 0);
      return examDate > latest ? examDate : latest;
    }, new Date(0))
  };

  const handleDeleteExam = async () => {
    if (!examToDelete) return;
    
    try {
      setIsLoading(true);
      await deleteExam(examToDelete);
      setShowDeleteDialog(false);
      setExamToDelete(null);
    } catch (error) {
      console.error('Delete exam error:', error);
      setError('Failed to delete exam. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50/50 via-purple-50/50 to-white pt-32 pb-16">
      <div className="max-w-7xl mx-auto px-4">
        {/* Header Section */}
        <div className="mb-12">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="flex items-center justify-between"
          >
            <div>
              <h1 className="text-4xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent mb-3">
                Practice Dashboard
              </h1>
              <p className="text-gray-600 text-lg">
                Track and manage your certification journey
              </p>
            </div>
            <motion.div
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <Button
                onClick={() => navigate('/practice')}
                className="bg-gradient-to-r from-blue-600 to-purple-600 text-white 
                         px-6 py-6 rounded-xl shadow-lg hover:shadow-xl
                         transition-all duration-300"
                size="lg"
              >
                <Plus className="w-5 h-5 mr-2" />
                New Practice Test
              </Button>
            </motion.div>
          </motion.div>
        </div>

        {/* Stats Overview */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12"
        >
          {[
            {
              title: 'Active Certifications',
              value: stats.activeCerts,
              icon: Award,
              color: 'blue'
            },
            {
              title: 'Total Attempts',
              value: stats.totalAttempts,
              icon: Target,
              color: 'purple'
            },
            {
              title: 'Last Practice',
              value: stats.lastActive.getTime() > 0 
                ? stats.lastActive.toLocaleDateString() 
                : 'Never',
              icon: Calendar,
              color: 'emerald'
            }
          ].map((stat, index) => (
            <motion.div
              key={stat.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 + index * 0.1 }}
            >
              <Card className="bg-white/90 backdrop-blur-md border border-gray-100 hover:shadow-lg
                           transition-all duration-300 overflow-hidden">
                <CardContent className="p-8">
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-sm font-medium text-gray-500 mb-2">{stat.title}</p>
                      <p className="text-3xl font-bold text-gray-900">{stat.value}</p>
                    </div>
                    <div className={`p-4 rounded-2xl bg-${stat.color}-50`}>
                      <stat.icon className={`w-8 h-8 text-${stat.color}-500`} />
                    </div>
                  </div>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </motion.div>

        {/* Certification Cards */}
        <div className="space-y-6">
          {user?.savedExams?.map((exam, index) => (
            <motion.div
              key={exam.metadata?.identifier?.certificationCode}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 + index * 0.1 }}
            >
              <Card className="group bg-white/90 backdrop-blur-md border border-gray-100
                           hover:shadow-xl hover:border-blue-100
                           transition-all duration-500 transform hover:-translate-y-1">
                <CardContent className="p-8">
                  <div className="flex flex-col space-y-6">
                    {/* Main Card Content */}
                    <div className="flex items-center justify-between">
                      <div className="flex-1 space-y-3">
                        <h3 className="text-xl font-semibold text-gray-900 group-hover:text-blue-600 transition-colors">
                          {exam.metadata?.identifier?.displayName}
                        </h3>
                        
                        <div className="flex items-center text-sm text-gray-500">
                          <BarChart className="w-4 h-4 mr-2" />
                          <span>
                            {Object.values(exam.domains || {}).reduce(
                              (sum, domain) => sum + (domain.attempts?.length || 0), 0
                            )} attempts across {Object.keys(exam.domains || {}).length} domains
                          </span>
                        </div>
                      </div>

                      <div className="flex items-center space-x-4">
                        <Button
                          onClick={() => {
                            localStorage.setItem('currentExam', JSON.stringify({
                              exam: {
                                ...exam,
                                content: {
                                  mode: 'practice',
                                  status: 'pending'
                                }
                              }
                            }));
                            navigate(`/test-mode/${exam.metadata.identifier.certificationCode}`);
                          }}
                          className="bg-gradient-to-r from-blue-500 to-purple-600 text-white
                                   px-6 py-6 rounded-xl shadow-md hover:shadow-lg
                                   transition-all duration-300"
                        >
                          Continue Practice
                          <ChevronRight className="w-4 h-4 ml-2" />
                        </Button>

                        <Button
                          variant="ghost"
                          onClick={() => {
                            setExamToDelete(exam);
                            setShowDeleteDialog(true);
                          }}
                          className="text-gray-400 hover:text-red-500 transition-colors p-3 rounded-lg
                                   hover:bg-red-50"
                        >
                          <Trash2 className="w-5 h-5" />
                        </Button>
                      </div>
                    </div>

                    {/* Previous Attempts Section */}
                    <div className="border-t border-gray-100 pt-6">
                      <div className="space-y-4">
                        <h4 className="text-sm font-semibold text-gray-700">Previous Attempts</h4>
                        
                        <div className="grid grid-cols-1 gap-3">
                          {/* Practice Mode Attempts */}
                          {Object.entries(exam.domains || {}).map(([domain, domainData]) => (
                            domainData.attempts?.map((attempt, attemptIndex) => (
                              <motion.div
                                key={`${domain}-${attemptIndex}`}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: attemptIndex * 0.1 }}
                                className="flex items-center justify-between p-4 rounded-lg bg-gray-50
                                         hover:bg-blue-50/50 transition-colors"
                              >
                                <div className="flex items-center space-x-4">
                                  <div className="w-10 h-10 rounded-lg bg-blue-100 flex items-center justify-center">
                                    <Target className="w-5 h-5 text-blue-600" />
                                  </div>
                                  <div>
                                    <p className="font-medium text-gray-900">{domain}</p>
                                    <div className="flex items-center text-sm text-gray-500 space-x-3">
                                      <span>{new Date(attempt.date).toLocaleDateString()}</span>
                                      <span>•</span>
                                      <span>{attempt.score}%</span>
                                      {attempt.timeTaken && (
                                        <>
                                          <span>•</span>
                                          <span>{attempt.timeTaken}</span>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <Button
                                  onClick={() => navigate(`/exam-result/${encodeURIComponent(exam.name)}/${attempt.id}`)}
                                  variant="ghost"
                                  className="text-blue-600 hover:text-blue-700 hover:bg-blue-50"
                                >
                                  Review Attempt
                                  <ChevronRight className="w-4 h-4 ml-2" />
                                </Button>
                              </motion.div>
                            )).reverse()
                          ))}

                          {/* Exam Mode Attempts */}
                          {exam.exams?.map((attempt, index) => (
                            <motion.div
                              key={`exam-${index}`}
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              transition={{ delay: index * 0.1 }}
                              className="flex items-center justify-between p-4 rounded-lg bg-purple-50/50
                                       hover:bg-purple-50 transition-colors"
                            >
                              <div className="flex items-center space-x-4">
                                <div className="w-10 h-10 rounded-lg bg-purple-100 flex items-center justify-center">
                                  <Timer className="w-5 h-5 text-purple-600" />
                                </div>
                                <div>
                                  <p className="font-medium text-gray-900">Full Exam Mode</p>
                                  <div className="flex items-center text-sm text-gray-500 space-x-3">
                                    <span>{new Date(attempt.date).toLocaleDateString()}</span>
                                    <span>•</span>
                                    <span>{attempt.score}%</span>
                                    {attempt.timeTaken && (
                                      <>
                                        <span>•</span>
                                        <span>{attempt.timeTaken}</span>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <Button
                                onClick={() => navigate(`/exam-result/${encodeURIComponent(exam.name)}/${attempt.id}`)}
                                variant="ghost"
                                className="text-purple-600 hover:text-purple-700 hover:bg-purple-50"
                              >
                                Review Attempt
                                <ChevronRight className="w-4 h-4 ml-2" />
                              </Button>
                            </motion.div>
                          )).reverse()}

                          {/* Empty State for No Attempts */}
                          {(!exam.exams?.length && !Object.values(exam.domains || {}).some(d => d.attempts?.length)) && (
                            <div className="text-center py-6 text-gray-500">
                              <p>No attempts yet. Start practicing to see your progress!</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </div>

        {/* Empty State */}
        {(!user?.savedExams || user.savedExams.length === 0) && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <Card className="bg-white/90 backdrop-blur-md text-center p-16 border border-gray-100">
              <div className="max-w-sm mx-auto">
                <div className="w-20 h-20 rounded-3xl bg-blue-50 flex items-center justify-center mx-auto mb-6">
                  <Award className="w-10 h-10 text-blue-500" />
                </div>
                <h3 className="text-2xl font-semibold text-gray-900 mb-3">
                  Start Your Journey
                </h3>
                <p className="text-gray-600 mb-8">
                  Begin your certification preparation with personalized practice tests
                </p>
                <Button
                  onClick={() => navigate('/practice')}
                  className="bg-gradient-to-r from-blue-600 to-purple-600 text-white
                           px-8 py-6 rounded-xl shadow-lg hover:shadow-xl
                           transition-all duration-300"
                  size="lg"
                >
                  Create Your First Practice Test
                  <ChevronRight className="w-5 h-5 ml-2" />
                </Button>
              </div>
            </Card>
          </motion.div>
        )}

        {/* Delete Dialog */}
        <AlertDialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
          <AlertDialogContent className="bg-white rounded-2xl p-6">
            <AlertDialogHeader>
              <AlertDialogTitle className="flex items-center text-xl">
                <AlertTriangle className="w-6 h-6 text-red-500 mr-2" />
                Delete Practice Test
              </AlertDialogTitle>
              <AlertDialogDescription className="text-gray-600 mt-2">
                Are you sure you want to delete this practice test and all its attempts?
                This action cannot be undone.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter className="mt-6">
              <AlertDialogCancel 
                disabled={isLoading}
                className="rounded-xl"
              >
                Cancel
              </AlertDialogCancel>
              <AlertDialogAction
                onClick={handleDeleteExam}
                className="bg-red-500 text-white hover:bg-red-600 rounded-xl"
                disabled={isLoading}
              >
                {isLoading ? 'Deleting...' : 'Delete Practice Test'}
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>

        {/* Error Toast */}
        <AnimatePresence>
          {error && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              className="fixed bottom-4 right-4 bg-white border border-red-200 
                       text-red-700 px-6 py-4 rounded-xl shadow-lg z-50"
            >
              <div className="flex items-center">
                <AlertTriangle className="w-5 h-5 mr-3" />
                <p>{error}</p>
                <button
                  className="ml-6 text-red-700 hover:text-red-800 p-1 hover:bg-red-50 rounded-lg
                           transition-colors"
                  onClick={() => setError(null)}
                >
                  ×
                </button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default AccountPage;