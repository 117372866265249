import React, { useState, useContext, useEffect, useMemo } from 'react';
import { UserContext } from '../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import {
  BookOpen,
  AlertTriangle,
  Play,
  RefreshCw,
  Timer,
  Info,
  ChevronRight,
  Check,
  Loader2
} from 'lucide-react';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { API_ENDPOINT } from '../../config/env';
import { prepareExamModeQuestions } from '../../lib/examMode';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';

const TestModeSelector = () => {
  const { user, saveGeneratedTest } = useContext(UserContext);
  const { certificationId } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [generatingStates, setGeneratingStates] = useState({});
  const [generatedStates, setGeneratedStates] = useState({});
  const [isGeneratingExam, setIsGeneratingExam] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const examData = useMemo(() => {
    try {
      const currentExam = localStorage.getItem('currentExam');
      if (currentExam) {
        const parsed = JSON.parse(currentExam);
        return parsed?.exam || null;
      }
      
      const savedExam = user?.savedExams?.find(exam => 
        exam.certificationCode === certificationId
      );
      
      if (savedExam) {
        return {
          metadata: {
            identifier: {
              certificationCode: certificationId,
              displayName: savedExam.name
            },
            domains: savedExam.domains
          }
        };
      }

      return null;
    } catch (error) {
      console.error('Error parsing exam data:', error);
      return null;
    }
  }, [certificationId, user?.savedExams]);

  const domains = examData?.metadata?.domains?.sub || [];
  const weightage = examData?.metadata?.domains?.weightage || {};
  const certCode = examData?.metadata?.identifier?.certificationCode || certificationId;

  useEffect(() => {
    const fetchExamData = async () => {
      if (examData) {
        setIsLoading(false);
        return;
      }

      try {
        const auth = getAuth();
        const idToken = await auth.currentUser.getIdToken();

        const response = await fetch(`${API_ENDPOINT}/exams/${certificationId}`, {
          headers: {
            'Authorization': idToken
          }
        });

        if (!response.ok) throw new Error('Failed to fetch exam data');

        const data = await response.json();
        localStorage.setItem('currentExam', JSON.stringify({ exam: data }));
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching exam:', error);
        setError('Failed to load exam data. Please try again.');
        setIsLoading(false);
      }
    };

    fetchExamData();
  }, [certificationId, examData]);

  useEffect(() => {
    if (!certCode || !domains.length) return;
    
    try {
      const savedStates = {};
      domains.forEach(domain => {
        const savedExam = user?.savedExams?.find(exam => 
          exam.certificationCode === certCode &&
          exam.domains?.[domain]?.questions?.length > 0
        );
        savedStates[domain] = !!savedExam;
      });
      setGeneratedStates(savedStates);
    } catch (error) {
      console.error('Error checking saved tests:', error);
    }
  }, [certCode, domains, user?.savedExams]);

  const allDomainsGenerated = useMemo(() => {
    if (!examData || !domains.length) return false;
    return domains.every(domain => generatedStates[domain]);
  }, [examData, domains, generatedStates]);

  const handleDomainSelect = async (domain, index) => {
    if (generatingStates[domain]) return;

    try {
      setGeneratingStates(prev => ({ ...prev, [domain]: true }));
      setError(null);

      const auth = getAuth();
      const idToken = await auth.currentUser.getIdToken();

      const response = await fetch(`${API_ENDPOINT}/generate-test`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': idToken
        },
        body: JSON.stringify({
          prompt: examData.metadata.identifier.displayName,
          metadata: examData.metadata,
          domain: domain,
          isFirstDomain: index === 0
        })
      });

      if (!response.ok) {
        throw new Error('Failed to generate test');
      }

      const data = await response.json();
      const { questions } = JSON.parse(data.result);

      // Save to backend
      await saveGeneratedTest(examData.metadata, domain, questions);

      // Update state
      setGeneratedStates(prev => ({ ...prev, [domain]: true }));
      setSelectedDomain(domain);

    } catch (error) {
      console.error('Error generating test:', error);
      setError(error.message || 'Failed to generate test. Please try again.');
    } finally {
      setGeneratingStates(prev => {
        const updated = { ...prev };
        delete updated[domain];
        return updated;
      });
    }
  };

  const handleStartPractice = async (domain) => {
    if (!domain || !generatedStates[domain]) return;
    
    try {
      setGeneratingStates(prev => ({ ...prev, [domain]: true }));
      
      const savedExam = user?.savedExams?.find(exam => 
        exam.certificationCode === certCode &&
        exam.domains?.[domain]?.questions?.length > 0
      );
  
      if (!savedExam?.domains?.[domain]?.questions) {
        throw new Error('No practice test data found. Please generate the test again.');
      }
  
      if (!examData?.metadata?.identifier?.displayName) {
        throw new Error('Invalid exam configuration. Please refresh and try again.');
      }
  
      const practiceExam = {
        exam: {
          name: examData.metadata.identifier.displayName,
          metadata: {
            ...examData.metadata,
            lastAccessed: Date.now(),
            domain: domain
          },
          content: {
            questions: savedExam.domains[domain].questions,
            mode: 'practice',
            domain: domain,
            status: 'active',
            generated: true,
            totalQuestions: savedExam.domains[domain].questions.length,
            completedBatches: 1,
            totalBatches: 1,
            progress: 0,
            timeStarted: Date.now()
          }
        },
        startTime: Date.now(),
        settings: {
          showTimer: true,
          allowPause: true,
          shuffleQuestions: true
        }
      };
  
      localStorage.setItem('currentExam', JSON.stringify(practiceExam));
      await new Promise(resolve => setTimeout(resolve, 300));
      navigate(`/practice-test/${certificationId}`, { state: { animated: true } });
  
    } catch (error) {
      console.error('Practice start error:', error);
      setError(error.message || 'Failed to start test. Please try again.');
    } finally {
      setGeneratingStates(prev => {
        const updated = { ...prev };
        delete updated[domain];
        return updated;
      });
    }
  };

  const handleStartExamMode = async () => {
    if (!allDomainsGenerated) return;

    try {
      setIsGeneratingExam(true);
      setError(null);

      const examModeQuestions = prepareExamModeQuestions(examData);
      
      const practiceExam = {
        exam: {
          name: examData?.metadata?.identifier?.displayName,
          metadata: examData?.metadata,
          content: {
            questions: examModeQuestions,
            mode: 'exam',
            status: 'active',
            generated: true,
            totalQuestions: examModeQuestions.length,
            completedBatches: 1,
            totalBatches: 1,
            timeStarted: Date.now()
          }
        },
        startTime: Date.now(),
        settings: {
          showTimer: true,
          allowPause: false,
          shuffleQuestions: true
        }
      };
      
      localStorage.setItem('currentExam', JSON.stringify(practiceExam));
      navigate(`/practice-test/${certificationId}`);
    } catch (error) {
      console.error('Error starting exam mode:', error);
      setError('Failed to start exam mode. Please try again.');
    } finally {
      setIsGeneratingExam(false);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-50 via-purple-50 to-white pt-32">
        <div className="max-w-3xl mx-auto px-4 text-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 mx-auto mb-4" />
          <p className="text-gray-600">Loading exam data...</p>
        </div>
      </div>
    );
  }

  if (!examData && !isLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-50 via-purple-50 to-white pt-32">
        <div className="max-w-3xl mx-auto px-4 text-center">
          <AlertTriangle className="h-8 w-8 text-red-500 mx-auto mb-4" />
          <h2 className="text-xl font-semibold text-gray-800 mb-2">Failed to Load Exam</h2>
          <p className="text-gray-600 mb-4">Unable to load exam data. Please try again.</p>
          <Button
            onClick={() => window.location.reload()}
            className="bg-gradient-to-r from-purple-500 to-blue-500 
                     hover:from-purple-600 hover:to-blue-600 text-white"
          >
            <RefreshCw className="w-4 h-4 mr-2" />
            Retry
          </Button>
        </div>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
      className="min-h-screen bg-gradient-to-br from-blue-50 via-purple-50 to-white"
    >
      <div className="max-w-3xl mx-auto px-4 pt-32 pb-8 space-y-8">
        {/* Header */}
        <div className="text-center space-y-3">
          <h1 className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
            {examData?.metadata?.identifier?.displayName}
          </h1>
          <p className="text-gray-600">
            Choose your practice mode to start preparing for your certification
          </p>
        </div>

        {/* Error Alert */}
        {error && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="bg-red-50 border border-red-200 rounded-lg p-4"
          >
            <div className="flex items-center space-x-2 text-red-600">
              <AlertTriangle className="w-5 h-5" />
              <p>{error}</p>
            </div>
          </motion.div>
        )}

        {/* Practice Mode Section */}
        <div className="space-y-4">
          <div className="flex items-center space-x-3 mb-4">
            <div className="p-2 rounded-full bg-blue-100">
              <BookOpen className="w-5 h-5 text-blue-600" />
            </div>
            <h2 className="text-xl font-semibold text-gray-900">Practice Mode</h2>
          </div>

          <div className="space-y-3">
            {domains.map((domain, index) => (
              <motion.div
                key={domain}
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: index * 0.1 }}
              >
                <Card 
                  className={`
                    transition-all duration-300 hover:shadow-md
                    ${selectedDomain === domain ? 'ring-2 ring-blue-500' : ''}
                  `}
                >
                  <CardContent className="p-4">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-3">
                        <div className={`
                          w-8 h-8 rounded-full flex items-center justify-center
                          ${generatedStates[domain] ? 'bg-green-100' : 'bg-gray-100'}
                        `}>
                          {generatingStates[domain] ? (
                            <Loader2 className="w-5 h-5 animate-spin text-blue-600" />
                          ) : generatedStates[domain] ? (
                            <Check className="w-5 h-5 text-green-600" />
                          ) : (
                            <span className="text-sm font-medium text-gray-600">{index + 1}</span>
                          )}
                        </div>
                        <div>
                          <h3 className="font-medium text-gray-900">{domain}</h3>
                          <p className="text-sm text-gray-500">
                            {weightage[domain] || 0}% of exam
                          </p>
                        </div>
                      </div>
                      
                      {generatedStates[domain] ? (
                        <Button
                          onClick={() => handleStartPractice(domain)}
                          disabled={generatingStates[domain]}
                          className="ml-auto bg-emerald-500 hover:bg-emerald-600 text-white"
                        >
                          <Play className="w-4 h-4 mr-2" />
                          Start
                        </Button>
                      ) : (
                        <Button
                          onClick={() => handleDomainSelect(domain, index)}
                          disabled={generatingStates[domain]}
                          className="ml-auto bg-gradient-to-r from-purple-500 to-blue-500 
                                   hover:from-purple-600 hover:to-blue-600 text-white"
                        >
                          Generate
                          <ChevronRight className="w-4 h-4 ml-2" />
                        </Button>
                      )}
                    </div>
                  </CardContent>
                </Card>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Exam Mode Section */}
        <div className="space-y-4">
          <div className="flex items-center space-x-3 mb-4">
            <div className="p-2 rounded-full bg-purple-100">
              <Timer className="w-5 h-5 text-purple-600" />
            </div>
            <h2 className="text-xl font-semibold text-gray-900">Exam Mode</h2>
          </div>

          <Card className="bg-white/90 backdrop-blur-md">
            <CardContent className="p-6 space-y-4">
              <div className="flex items-start space-x-3">
                <Info className="w-5 h-5 text-gray-500 mt-1 flex-shrink-0" />
                <div className="space-y-2">
                  <p className="text-sm text-gray-600">
                    Complete all domain practices to unlock the full exam mode. The exam will:
                  </p>
                  <ul className="text-sm text-gray-500 space-y-2">
                    <li className="flex items-center space-x-2">
                      <span className="w-1.5 h-1.5 bg-gray-400 rounded-full" />
                      <span>Include questions from all domains</span>
                    </li>
                    <li className="flex items-center space-x-2">
                      <span className="w-1.5 h-1.5 bg-gray-400 rounded-full" />
                      <span>Follow official exam weightage</span>
                    </li>
                    <li className="flex items-center space-x-2">
                      <span className="w-1.5 h-1.5 bg-gray-400 rounded-full" />
                      <span>Have timed conditions</span>
                    </li>
                  </ul>
                </div>
              </div>

              <Button
                onClick={handleStartExamMode}
                disabled={!allDomainsGenerated || isGeneratingExam}
                className="w-full bg-gradient-to-r from-purple-500 to-blue-500 
                         hover:from-purple-600 hover:to-blue-600 text-white"
              >
                {!allDomainsGenerated ? (
                  <>
                    <RefreshCw className="w-4 h-4 mr-2" />
                    Complete All Domains First
                  </>
                ) : isGeneratingExam ? (
                  <>
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                    Preparing Exam
                  </>
                ) : (
                  <>
                    <Play className="w-4 h-4 mr-2" />
                    Start Full Practice Exam
                  </>
                )}
              </Button>
            </CardContent>
          </Card>
        </div>
      </div>
    </motion.div>
  );
};

export default TestModeSelector;