import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../context/UserContext';

const NavigationBar = () => {
  const { user } = useContext(UserContext);

  return (
    <nav className="absolute top-8 left-0 right-0 z-50">
      <div className="relative max-w-7xl mx-auto">
        {/* Centered navigation */}
        <div className="max-w-2xl mx-auto">
          <div className="flex justify-center gap-20">
            <Link 
              to="/" 
              className="text-base font-semibold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent hover:from-blue-700 hover:to-purple-700 transition-all hover:scale-105"
            >
              About
            </Link>
            <Link 
              to="/practice" 
              className="text-base font-semibold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent hover:from-blue-700 hover:to-purple-700 transition-all hover:scale-105"
            >
              Practice
            </Link>
            <Link 
              to="/faq"
              className="text-base font-semibold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent hover:from-blue-700 hover:to-purple-700 transition-all hover:scale-105"
            >
              FAQ
            </Link>
          </div>
        </div>

        {/* Account button on right */}
        <div className="absolute right-8 top-1/2 -translate-y-1/2">
          <Link
            to={user ? "/account" : "/login"}
            className="px-4 py-2 rounded-full text-sm font-semibold bg-gradient-to-r from-blue-600 to-purple-600 text-white hover:opacity-90 transition-all hover:scale-105 flex items-center"
          >
            {user ? (
              <>
                <span className="mr-1">👋</span>
                {user.displayName || user.email?.split('@')[0] || 'Account'}
              </>
            ) : (
              'Login'
            )}
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;