import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { analytics } from './firebaseConfig';
import { setAnalyticsCollectionEnabled } from "firebase/analytics";
import UserProvider from './components/context/UserContext';
import { ToastProvider } from './components/ui/toast';
import ErrorBoundary from './components/ui/error-boundary';

// Layout Components
import Footer from './components/layout/Footer';
import NavigationBar from './components/layout/NavigationBar';
// Common Components
import CookieConsentBanner from './components/common/CookieConsentBanner';

// Page Components
import AboutPage from './components/pages/AboutPage';
import LLMInteraction from './components/pages/LLMInteraction';
import Account from './components/pages/AccountPage';
// import Pricing from './components/pages/Pricing';
import FAQPage from './components/pages/FAQPage';
import ContactPage from './components/pages/ContactPage';
import LegalHub from './components/pages/LegalHub';

// Auth Components
import Login from './components/auth/Login';
import SignUp from './components/auth/SignUp';
import ResetPassword from './components/auth/ResetPassword';

// Exam Components
import PracticeTestPlayground from './components/exam/PracticeTestPlayground';
import ExamResultScreen from './components/exam/ExamResultScreen';
import AttemptReview from './components/exam/AttemptReview';
import ExamAnalytics from './components/exam/ExamAnalytics';
import TestModeSelector from './components/exam/TestModeSelector';  // Add this import

// Legal Pages
import PrivacyPolicyPage from './components/pages/legal/PrivacyPolicyPage';
import TermsOfUsePage from './components/pages/legal/TermsOfUsePage';
import CookiePolicyPage from './components/pages/legal/CookiePolicyPage';
import AcceptableUsePage from './components/pages/legal/TermsOfUsePage';

// Payment/Checkout
import Checkout from './components/pages/Checkout';
import SubscriptionSuccess from './components/pages/SubscriptionSuccess';

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

// Layout wrapper component
const Layout = ({ children }) => {
  const location = useLocation();
  
  // Define routes where navigation and footer should be hidden
  const hideLayoutRoutes = [
    '/practice-test',
    '/practice-test/',
  ];

  const shouldHideLayout = hideLayoutRoutes.some(route => 
    location.pathname.startsWith(route)
  );

  return (
    <>
      {!shouldHideLayout && <NavigationBar />}
      {children}
      {!shouldHideLayout && <Footer />}
    </>
  );
};

function AppContent() {
  const [analyticsEnabled, setAnalyticsEnabled] = useState(false);

  const handleConsent = (consent) => {
    setAnalyticsEnabled(consent);
    setAnalyticsCollectionEnabled(analytics, consent);
  };

  useEffect(() => {
    const consentGiven = localStorage.getItem('cookieConsentGiven');
    if (consentGiven === 'true') {
      handleConsent(true);
    }
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-white to-purple-50">
      <Layout>
        <Routes>
          {/* Main Routes */}
          <Route path="/" element={<AboutPage />} />
          <Route path="/practice" element={<LLMInteraction />} />
          <Route path="/account" element={<Account />} />
          {/* <Route path="/pricing" element={<Pricing />} /> */}
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/contact" element={<ContactPage />} />
          
          {/* Auth Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          
          {/* Exam Routes */}
          <Route path="/test-mode/:certificationId" element={<TestModeSelector />} />
          <Route path="/practice-test" element={<PracticeTestPlayground />} />
          <Route path="/practice-test/:examId" element={<PracticeTestPlayground />} />
          <Route path="/exam-result/:examId/:attemptId" element={<ExamResultScreen />} />
          <Route path="/exam-result/:examId/:attemptId" element={<AttemptReview />} />
          <Route path="/exam-analytics/:examId" element={<ExamAnalytics />} />
          
          {/* Payment Routes */}
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/subscription/success" element={<SubscriptionSuccess />} />
          
          {/* Legal Routes */}
          <Route path="/legal" element={<LegalHub />} />
          <Route path="/legal/privacy" element={<PrivacyPolicyPage />} />
          <Route path="/legal/terms" element={<TermsOfUsePage />} />
          <Route path="/legal/cookies" element={<CookiePolicyPage />} />
          <Route path="/legal/acceptable-use" element={<AcceptableUsePage />} />
        </Routes>
      </Layout>
      <CookieConsentBanner onConsent={handleConsent} />
    </div>
  );
}

function App() {
  return (
    <ErrorBoundary>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <UserProvider>
          <ToastProvider>
            <Router>
              <AppContent />
            </Router>
          </ToastProvider>
        </UserProvider>
      </GoogleOAuthProvider>
    </ErrorBoundary>
  );
}

export default App;